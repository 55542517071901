import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar, Toolbar, Typography, List, ListItem,
  withStyles, Grid, SwipeableDrawer
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { motion } from 'framer-motion';
import {Link} from 'react-scroll';
import Logo from '../../assets/img/Logo/CloudinityLogo.png';

const styleSheet = {
  list : {
    width : "200px",
  },
  padding : {
    paddingRight : "30px",
    cursor : "pointer",
  },

  sideBarIcon : {
    padding : "0px",
    color : "white",
    cursor : "pointer",
  },
  AppBar: {
      background: "#232f3e",
      boxShadow: "none",
  },
  smallNav: {
    backgroundColor: "#232f3e",
  },
  Logo: {
      maxWidth: "120px",
  },
  title:{
      borderStyle: "none",
      margin: "20px",
      fontSize: "14px",
      backgroundColor: "transparent",
      color: "#ffffff",
      position:"relative",
      textDecoration: "none",
  },
}



const Hover = {
    color: '#e47911', 
}


class ResAppBar extends Component{
  constructor(props){
    super(props);
    this.state = {drawerActivate:false, drawer:false};
    this.createDrawer = this.createDrawer.bind(this);
    this.destroyDrawer = this.destroyDrawer.bind(this);
  }

  componentWillMount(){
    if(window.innerWidth <= 600){
      this.setState({drawerActivate:true});
    }

    window.addEventListener('resize',()=>{
      if(window.innerWidth <= 600){
        this.setState({drawerActivate:true});
      }
      else{
        this.setState({drawerActivate:false})
      }
    });
  }

  //Small Screens
  createDrawer(){
    return (
      <div>
        <AppBar className = {this.props.classes.AppBar}>
          <Toolbar>
            <Grid container direction = "row" justify = "space-between" alignItems="center">
              <MenuIcon
                className = {this.props.classes.sideBarIcon}
                onClick={()=>{this.setState({drawer:true})}} />

                <img  className = {this.props.classes.Logo} src = {Logo} alt = "logo" />
              <Typography color="inherit" variant = "headline"></Typography>
            </Grid>
          </Toolbar>
        </AppBar>

        <SwipeableDrawer
         open={this.state.drawer}
         onClose={()=>{this.setState({drawer:false})}}
         onOpen={()=>{this.setState({drawer:true})}}>

           <div className = {this.props.classes.smallNav}
             tabIndex={0}
             role="button"
             onClick={()=>{this.setState({drawer:false})}}
             onKeyDown={()=>{this.setState({drawer:false})}}>

            <List className = {this.props.classes.list}>
               <ListItem key = {1} button divider>
               <motion.button
                        color="inherit" className={this.props.classes.title}
                        whileHover = {Hover}
                        
                    >
                        <Link activeClass="active" to="Home" spy={true} smooth={true}>Home</Link>
                    </motion.button>
               </ListItem>
               <ListItem key = {2} button divider>
               <motion.button
                        color="inherit" className={this.props.classes.title}
                        whileHover = {Hover}
                        
                    >
                            <Link activeClass="active" to="WhatWeDo" spy={true} smooth={true}>What We Do</Link>
                    </motion.button>
                </ListItem>
               <ListItem key = {3} button divider>
               <motion.button
                        color="inherit" className={this.props.classes.title}
                        whileHover = {Hover}
                        
                    >
                           <Link activeClass="active" to="AboutUs" spy={true} smooth={true}>About Us</Link>
                    </motion.button>
               </ListItem>
               {/* <ListItem key = {4} button divider>
               <motion.button
                        color="inherit" className={this.props.classes.title}
                        whileHover = {Hover}
                        
                    >
                            <Link activeClass="active" to="Gallery" spy={true} smooth={true}>Clients</Link>
                    </motion.button>
                </ListItem>                */}
               <ListItem key = {5} button divider> 
               <motion.button
                        color="inherit" className={this.props.classes.title}
                        whileHover = {Hover}
                        
                    >
                        <Link activeClass="active" to="Contact" spy={true} smooth={true}>Contact</Link>
                    </motion.button>
               </ListItem>
             </List>

         </div>
       </SwipeableDrawer>

      </div>
    );
  }

  //Larger Screens
  destroyDrawer(){
    return (
      <AppBar className = {this.props.classes.AppBar}>
        <Toolbar>
        <img  className = {this.props.classes.Logo} src = {Logo} alt = "logo" />
                    <Typography style = {{flex: 1}}></Typography>
                    <motion.button
                        color="inherit" className={this.props.classes.title}
                        whileHover = {Hover}
                        
                    >
                        <Link activeClass="active" to="Home" spy={true} smooth={true}>Home</Link>
                    </motion.button>
                    <motion.button
                        color="inherit" className={this.props.classes.title}
                        whileHover = {Hover}
                        
                    >
                            <Link activeClass="active" to="WhatWeDo" spy={true} smooth={true}>What We Do</Link>
                    </motion.button>
                    <motion.button
                        color="inherit" className={this.props.classes.title}
                        whileHover = {Hover}
                        
                    >
                           <Link activeClass="active" to="AboutUs" spy={true} smooth={true}>About Us</Link>
                    </motion.button>
                    {/* <motion.button
                        color="inherit" className={this.props.classes.title}
                        whileHover = {Hover}
                        
                    >
                           <Link activeClass="active" to="Gallery" spy={true} smooth={true}>Clients</Link>
                    </motion.button>                     */}
                    <motion.button
                        color="inherit" className={this.props.classes.title}
                        whileHover = {Hover}
                        
                    >
                        <Link activeClass="active" to="Contact" spy={true} smooth={true}>Contact</Link>
                    </motion.button>
        </Toolbar>
      </AppBar>
    )
  }

  render(){
    return(
      <div>
        {this.state.drawerActivate ? this.createDrawer() : this.destroyDrawer()}
      </div>
    );
  }
}

ResAppBar.propTypes = {
  classes : PropTypes.object.isRequired
};



export default withStyles(styleSheet)(ResAppBar);
