import React from 'react';
import LandingPage from '../LandingPage/LandingPage';

const Home = () => {
    return (
        <div className = "Home">
            <LandingPage />
        </div>
    );
}

export default Home;