const CloudAndDataCenter = () => {
    return (
        <div>
            Cloud migration is a difficult undertaking for businesses of any size, and organizations of every type. 
            Consider the complexities inherent in legacy infrastructure that must be identified and 
            stripped away before the move can happen. 
            If this sounds like you, then it’s time to talk to us about how we make migrating data from 
            on-premise systems simple.
        </div>

    );
}
export default CloudAndDataCenter;