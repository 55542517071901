const CyberSecurity = () => {
    return (
        <div>           
            Cloud technology has made it easier for businesses to manage their infrastructure and data, 
            but this transition means opening up a new problem, cybersecurity. 
            Cybercrime is at an unprecedented level with hackers targeting just about anyone. 
            Cloudity was started to give users the option of secure migrations without any downtime, 
            and at a reasonable price. Contact our specialists to find out more about on-prem, 
            hybrid and cloud solutions that will keep you up with this rapidly changing digital world.

        </div>

    );
}
export default CyberSecurity;