const SmartIVR = () => {
    return (
        <div>           
            We use Amazon Connect because it  offers low-cost voice services to connect with your customers. 
            With Amazon Connect's IVR, you can offer callers the same self-service options they would have 
            if speaking live on the phone and use natural language processing to answer inbound calls from 
            customers who don't want to wait or listen to prompts.
        </div>

    );
}
export default SmartIVR;