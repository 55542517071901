const DataAndAI = () => {
    return (
        <div>           
            Cloud data analytics can be a valuable tool for businesses that are looking to cut costs by reducing their 
            reliance on expensive infrastructure and hardware, as well as gain access to information anywhere/anytime. 
            Cloudinity is a leader in the field of cloud analytics. With our help, 
            businesses can more efficiently process and report data findings, collaborate easier with one another to 
            enhance decision-making processes, and provide leaders faster access to business intelligence while gaining 
            all these benefits themselves.

        </div>

    );
}
export default DataAndAI;