import React from "react";
import { makeStyles } from "@material-ui/core";

import MailIcon from "@material-ui/icons/Mail";
import Business from "@material-ui/icons/Business";
import Phone from "@material-ui/icons/Phone";
import { Grid } from "@material-ui/core";

import { Card } from "react-bootstrap";
//const currentYear = new Date().getFullYear();
const useStyles = makeStyles((theme) => ({
  Card: {
    backgroundColor: "#37475a",
  },
  Footer: {
    textAlign: "center",
    color: "#cccccc",
  },
}));
const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card className={classes.Card}>
        <Card.Footer className={classes.Footer}>
          <Grid container spacing={3}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <Business />&nbsp;
              <a href="https://goo.gl/maps/Zy2etq1P1cLfjKHr9">
                7651 Mill Springs Dr, Dublin, OH-43016
              </a>{" "}
              &nbsp;&nbsp;
              <MailIcon />&nbsp;
              <a href="contactus@cloudinityinc.com">
                srikanth.gunday@cloudinityinc.com  
              </a>
              &nbsp;&nbsp;
              <Phone />&nbsp;
              <a href="tel:+16148938647">+1 (614)-893-8647</a>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <div class="container">
            <p>&copy; <span id="currentYear"></span> All Rights Reserved - Cloudinity</p>
          </div>
          
        </Card.Footer>
      </Card>
    </div>
  );
};
//document.getElementById('currentYear').textContent = currentYear;
export default Footer;
