import React, { useState } from 'react';

import img1 from './img1.png';
import img2 from './img2.jpg';
import img3 from './img3.png';
// import img4 from './img4.jpeg';
// import img5 from './img5.png';
// import img6 from './img6.jpeg';

const ImageGallery = () => {
  const [images] = useState([{src: img1, alt: 'Image 1', text: 'CAGE/NCAGE: 95PH5'},{ src: img2, alt: 'Image 2',  text: 'GSA MAS Contract Number: 47QTCA23D000F' },{ src: img3, alt: 'Image 3',  text: 'DUNS-118166253' }]);

  const imageStyle = {
    display: 'inline-block',
    width: '150px',
    height: '150px',
    objectFit: 'contain',
    margin: '0 50px',
    
  };

//   const getImageName = (index) => {
//     const imageNames = ['CAGE/NCAGE: 95PH5', 'GSA MAS Contract Number: 47QTCA23D000F', 'DUNS-118166253'];
//     return imageNames[index];
//   };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '100px',
  };

  const textStyle = {
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    color: '#3741ad',
    fontWeight: 'bold',
  };

//   const imageCaption =  {
//     textalign: 'center',
//     fontsize: '14px',
//     margintop: '5px',
//   };
  
//   return (
//     <div style={containerStyle}>
//       {images.map((imageUrl, index) => (
//         <div key={index} style={imageStyle}>
//           <img src={imageUrl} alt={`Image ${index + 1}`} style={imageStyle} />
//           <p className="imageCaption">{getImageName(index)}</p>
//         </div>
//       ))}
//     </div>
//   );

  

return (
    <div style={containerStyle}>
      {images.map((image, index) => (
        <div key={index}>
          <img src={image.src} alt={image.alt} style={imageStyle} />
          <p style={textStyle}>{image.text}</p>
        </div>
      ))}
    </div>
  );
  
};

export default ImageGallery;



  

  