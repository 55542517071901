import React from 'react';
import { Card, Container } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';

//import AWSManagedServices from "../../assets/img/Icons/AWSManagedServices.png";
import AppModernization from "../../assets/img/Icons/AppModernization.png";
import CloudAndDataCenter from "../../assets/img/Icons/CloudAndDataCenter.png";
import DataAndAI from "../../assets/img/Icons/DataAndAI.png";
import SmartIVR from "../../assets/img/Icons/SmartIVR.png";
import Security from "../../assets/img/Icons/Security.png";


//import Content1 from '../../assets/Content/WhatWeDo/AWSManagedServices';
import Content2 from '../../assets/Content/WhatWeDo/AppModernization';
import Content3 from "../../assets/Content/WhatWeDo/CloudAndDataCenter";
import Content4 from "../../assets/Content/WhatWeDo/DataAndAI";
import Content5 from "../../assets/Content/WhatWeDo/CyberSecurity";
import Content6 from "../../assets/Content/WhatWeDo/SmartIVR";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 10,
        padding: 10,
        fontFamily: "sans-serif",
    },
    h1: {
        margin: 10,
        letterSpacing: "2px",
        textTransform: "uppercase",
        color: "#232f3e",
        textAlign: "center",
    },
    circleDiv:{
        margin: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    circle: {
        height: "5px",
        width: "5px",
        margin: "0 10px 0 10px",
        backgroundColor: "#d4d4d4",
        borderRadius: "50%",
        display: "inline-block",
    },
    container: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      },
    Cardone: {
        height: "420px",
        width: "350px",
        border: "none",
        margin: "5px",
        justifyContent: "center",
        alignItems: "center",
        animation: "$fadeLeft 4s",
        "&:hover":{
            boxShadow: "0 0 11px rgba(33,33,33,.2)"
        }
    },

    Cardtwo: {
        height: "420px",
        width: "350px",
        border: "none",
        margin: "5px",
        justifyContent: "center",
        alignItems: "center",
        animation: "$fadeLeft 3s",
        "&:hover":{
            boxShadow: "0 0 11px rgba(33,33,33,.2)"
        }
    },

    Cardthree: {
        height: "420px",
        width: "350px",
        border: "none",
        margin: "5px",
        justifyContent: "center",
        alignItems: "center",
        animation: "$fadeLeft 2s",
        "&:hover":{
            boxShadow: "0 0 11px rgba(33,33,33,.2)"
        }
    },

    Cardfour: {
        height: "420px",
        width: "350px",
        border: "none",
        margin: "5px",
        justifyContent: "center",
        alignItems: "center",
        animation: "$fadeLeft 4s",
        "&:hover":{
            boxShadow: "0 0 11px rgba(33,33,33,.2)"
        }
    },

    Cardfive: {
        height: "420px",
        width: "350px",
        border: "none",
        margin: "5px",
        justifyContent: "center",
        alignItems: "center",
        animation: "$fadeLeft 3s",
        "&:hover":{
            boxShadow: "0 0 11px rgba(33,33,33,.2)"
        }
    },

    Cardsix: {
        height: "420px",
        width: "350px",
        border: "none",
        margin: "5px",
        justifyContent: "center",
        alignItems: "center",
        animation: "$fadeLeft 2s",
        "&:hover":{
            boxShadow: "0 0 11px rgba(33,33,33,.2)"
        }
    },


    Img: {
        margin: "10px",
        width: "70px",
        height: "70px",     
    },
    title: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#333",
        textAlign: "center",
    },
    text: {
        fontSize: "14px",
        color: "#333",
        textAlign: "center", 
        fontFamily: "Arial, sans-serif",  
    },
    "@keyframes fadeLeft": {
        "from": {
            opacity: "0",
            transform: "translate3d(-100%,0,0)",
        },
        "to": {
            opacity: "1",
            transform: "translate(-100%,0,0)",
        },
    },
  }));
  

const WhatWeDo = () =>{
    const classes = useStyles();
    return (
        <div className = {classes.root} id = "WhatWeDo">
            <h1 className = {classes.h1}>What We Do</h1>
            <div className = {classes.circleDiv}>
                <div className = {classes.circle}></div>
                <div className = {classes.circle}></div>
                <div className = {classes.circle}></div>
            </div>
            <Container className = {classes.container}>
               {/* <Card className = {classes.Cardone}>
                    <Card.Img className = {classes.Img} src = {AWSManagedServices} />
                        <Card.Body>
                            <Card.Title className = {classes.title}>AWS Managed Services</Card.Title>
                            <Card.Text className = {classes.text}>
                                <Content1 />
                            </Card.Text>
                        </Card.Body>
    </Card>*/}
                    <Card className = {classes.Cardtwo}>
                    <Card.Img className = {classes.Img} src = {AppModernization} />
                        <Card.Body>
                            <Card.Title className = {classes.title}>App Modernization</Card.Title>
                            <Card.Text className = {classes.text}>
                                <Content2 />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className = {classes.Cardthree}>
                    <Card.Img className = {classes.Img} src = {CloudAndDataCenter} />
                        <Card.Body>
                            <Card.Title className = {classes.title}>Cloud and Data Center Modernization</Card.Title>
                            <Card.Text className = {classes.text}>
                                <Content3 />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className = {classes.Cardfour}>
                    <Card.Img className = {classes.Img} src = {DataAndAI} />
                        <Card.Body>
                            <Card.Title className = {classes.title}>Data and AI</Card.Title>
                            <Card.Text className = {classes.text}>
                                <Content4 />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className = {classes.Cardfive}>
                        <Card.Img className = {classes.Img} src = {Security} />
                        <Card.Body>
                            <Card.Title className = {classes.title}>Cybersecurity</Card.Title>
                            <Card.Text className = {classes.text}>
                                <Content5 />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className = {classes.Cardsix}>
                        <Card.Img className = {classes.Img} src = {SmartIVR} />
                        <Card.Body>
                            <Card.Title className = {classes.title}>Smart IVR Technology</Card.Title>
                            <Card.Text className = {classes.text}>
                                <Content6 />
                            </Card.Text>
                        </Card.Body>
                    </Card>
            </Container>
        </div>
    );
}

export default WhatWeDo;
