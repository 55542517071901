import React from 'react';

import NavBar from './Components/NavBar/NavBar';
import Home from './Components/Home/Home';
import WhatWeDo from './Components/WhatWeDo/whatWeDo';
import AboutUs from "./Components/AboutUs/AboutUs";
import Gallery from "./Components/Gallery/Gallery"
import Contact from './Components/Contact/Contact';
import Footer from "./Components/Footer/Footer";


function App() {
  return (
    <div className="App">
        <NavBar />
        <Home id = "Home"/>
        <WhatWeDo id = "WhatWeDo"/>
        <AboutUs id = "AboutUs"/>
        <Gallery id = "Gallery"/>
        <Contact id = "Contact"/>
        <Footer/>
    </div>
  );
}

export default App;
