import React from 'react';
import { makeStyles } from '@material-ui/core';
import AboutContent from '../../assets/Content/AboutUs/About';
import Background from "../../assets/img/background/background.png";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "0px",
        paddingTop: "20px",
        backgroundColor: "#FAFAFA",
        fontFamily: "sans-serif"
    },
    h1: {
        letterSpacing: "2px",
        textTransform: "uppercase",
        color: "#232f3e",
        textAlign: "center",
    },
    circleDiv:{
        margin: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    circle: {
        height: "5px",
        width: "5px",
        margin: "0 10px 0 10px",
        backgroundColor: "#d4d4d4",
        borderRadius: "50%",
        display: "inline-block",
    },
    flexContainer: {
        display: "flex",
        flexDirection: "column reverse",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
    },
    mainContent: {
        maxWidth: "800px",
        margin: "10px",
        textJustify: "auto",
    },
  
    img: {
        marginLeft: "60px", 
        padding: "0px",
        width: "300px",
        height: "300px",
    },
    TitleMessage: {
        textAlign: "left",
        maxWidth: "800px",
    },
  }));


const AboutUs = () =>{
    const classes = useStyles();
    return(
        <div className = {classes.root} id  = "AboutUs">
            <h1 className = {classes.h1}>About Us</h1>
            <div className = {classes.circleDiv}>
                <div className = {classes.circle}></div>
                <div className = {classes.circle}></div>
                <div className = {classes.circle}></div>
            </div>
            <div className = {classes.flexContainer}>
                    <section className = {classes.mainContent}>
                        <AboutContent/>
                    </section>
                    <div className = {classes.sideBar}>
                        <img 
                            src = {Background} 
                            alt = "BackgroundImage" 
                            className = {classes.img}
                        />
                    </div>    
            </div>
        </div>
    );
}

export default AboutUs;