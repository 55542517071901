const AppModernization = () => {
    return (
        <div>
           Cloudinity is a leading provider of application modernization solutions and guidance. 
           We offer services to help you build, run, manage all your apps across any cloud seamlessly with our 
           experienced team who will guide the process from start to finish in an effortless way for even the most 
           demanding customers.

        </div>

    );
}
export default AppModernization