import React from 'react';
import { makeStyles } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
    h2: {
        fontFamily: "sans-serif",  
        textAlign: "left",
        color: "#333",
        fontWeight: 'bold',
    },
    p:{
        fontFamily: "Arial, sans-serif", 
        textAlign: "left",
        color: "#333",
        fontSize: "18px",
    }
  }));

const TitleMessage = () =>{
    
    const classes = useStyles();
    return (
        <div className = "TitleMessage">
            <h2 className = {classes.h1}>Cloudinity Inc. MBE Certified Company.</h2>
            <p className = {classes.p}>
            Cloudinity Inc., the leading cloud infrastructure provider, 
            has launched a new solution for digital transformation services. 
            We are targeting business customers who are looking to leverage existing 
            IT resources and integrate them with their data centers to improve operations efficiency and reduce costs. 
            Current offerings include Datacenter Modernization Services that help companies incorporate virtualized 
            environments into an enterprise strategy; Application Modernization Services which allows businesses to
             modernize legacy applications so they can be run on different servers or distributed computing platforms 
             such as Amazon Web Service (AWS); Cloud Security Solutions designed specifically for enterprises concerned 
             about protecting sensitive information from unauthorized accesses when using public clouds like AWS.  
            </p>
        </div>
    );

}

export default TitleMessage
